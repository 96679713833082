export const ADD_TO_CART = {
  FORM_METHOD: 'POST',
  RE_AUTH_ROUTE: '/services/reauth',
  EVENT_UPDATED: 'cartUpdated',
  EVENT_SUCCESS: 'cartSuccess',
  EVENT_ERROR: 'cartError',
  EVENT_ADDED: 'cartProductAdded',
  RESPONSE_SUCCESS: 'success',
  RESPONSE_ERROR: 'error',
  DATA_CERBERUS_ATC: 'ATC_NOT_ADDED_PRODUCTS_LIST',
  CUSTOMER_SALES_COOKIE_KEY: 'elo.customerSales'
};
export const HTTP_HEADERS = {
  'Content-Type': 'application/json;charset=UTF-8'
};
export const MODAL = {
  ERROR_SELECTOR: 'popin-cart--error',
  CONTENT_SELECTOR: 'popin-cart__content',
  FOOTER_SELECTOR: 'mc-modal__footer button:last-of-type',
  SEE_CART_SELECTOR: 'js-atc-popin-see-cart',
  REDIRECT_SELECTOR: 'js-cart-redirect-url',
  CLOSE_SELECTOR: 'js-modal-close',
  MODE: {
    DEFAULT: 'default',
    CART: 'cart'
  }
};
export const LAYER = {
  SELECTOR: 'js-atc-layer',
  RESPONSE_SELECTOR: 'js-response-layer',
  ADDED_CONTAINER_SELECTOR: 'js-act-container',
  LOADER_SELECTOR: 'js-atc-layer-loader'
};
export const HIDDEN_CSS_CLASS = 'kl-hidden';