import AddToCartModule from '../addtocartmodule/add-to-cart.module';
class AddToCartCustomer extends lm.Composant {
  constructor(id) {
    super(id);
  }
  init() {
    const addToCartModule = new AddToCartModule('customer');
    addToCartModule.init();
  }
}
lm.DOMReady(() => {
  const addToCartCustomerInstance = new AddToCartCustomer('cartcdp');
  addToCartCustomerInstance.init();
});