import Modal from '@mozaic-ds/freemarker/js/modules/_modal.js';
import { MODAL } from '../addtocartmodule/add-to-cart.constants';
export default class ModalBuilder {
  constructor() {
    this.errorModal = new Modal({
      classContent: MODAL.ERROR_SELECTOR
    });
  }
  display(configuration) {
    const {
      content,
      action,
      refreshAfterClose
    } = configuration;
    this.errorModal.modal.querySelector(`.${MODAL.CONTENT_SELECTOR}`).innerHTML = content;
    if (action) {
      this.errorModal.modal.querySelector(`.${MODAL.FOOTER_SELECTOR}`).addEventListener('click', action, {
        once: true
      });
    }
    this.errorModal.open();
    const seeCartButton = this.errorModal.modal.querySelector(`.${MODAL.SEE_CART_SELECTOR}`);
    if (seeCartButton) seeCartButton.addEventListener('click', () => {
      window.location.href = this.errorModal.modal.querySelector(`.${MODAL.REDIRECT_SELECTOR}`).value;
    }, {
      once: true
    });
    if (refreshAfterClose) {
      const refreshButtons = this.errorModal.modal.querySelectorAll(`.${MODAL.CLOSE_SELECTOR}`);
      refreshButtons.forEach(button => {
        button.addEventListener('click', () => {
          window.location.reload();
        });
      });
    }
  }
}