import { enableDisabledAllATCButtons, isAllAtcButtonsDisabled } from '../tools/utils.class';
import AddToCartLayerMode from './add-to-cart-layer-mode.class';
export default class AddToCartModule {
  constructor(type) {
    this.addToCartLayer = new AddToCartLayerMode(type);
  }
  init() {
    this.listen();
  }
  listen() {
    document.addEventListener('cartUpdate', event => {
      if (event && event.detail) {
        if (!isAllAtcButtonsDisabled()) {
          enableDisabledAllATCButtons(true);
          const products = event.detail.listProducts;
          document.cookie = `CART_PRODUCT=${products[0].reflm}; path=/`;
          this.addToCartLayer.updateCart({
            products,
            setCookie: true
          });
        }
      }
    });
  }
}