import FormBuilder from '../tools/form-builder.class';
import { ADD_TO_CART } from './add-to-cart.constants';
export default class CheckCartService {
  reAuth() {
    const formAction = ADD_TO_CART.RE_AUTH_ROUTE;
    const formBuilder = new FormBuilder(ADD_TO_CART.FORM_METHOD, formAction);
    formBuilder.addInput({
      name: 'callback',
      type: 'text',
      value: window.location.pathname
    });
    formBuilder.addFormInBody();
    formBuilder.submitForm();
  }
}