import { getTmsInstance } from 'integration-web-core--socle/js/assets/commons/_tms.js';
const ADD_CART_SELECTOR = 'js-cart-add';
export const enableDisabledAllATCButtons = enabled => {
  const atcButtons = document.querySelectorAll(`.${ADD_CART_SELECTOR}`);
  if (atcButtons) {
    atcButtons.forEach(button => {
      button.disabled = enabled;
    });
  }
};
export const isAllAtcButtonsDisabled = () => {
  const atcButtons = document.querySelectorAll(`.${ADD_CART_SELECTOR}`);
  return Array.from(atcButtons).some(element => element.disabled);
};
export const dispatchAtcTracking = options => {
  const {
    target,
    eventType,
    trackingData
  } = options;
  getTmsInstance().pushEvent(target, eventType, trackingData);
};