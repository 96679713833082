export default class FormBuilder {
  constructor(method = 'POST', action) {
    this.form = document.createElement('form');
    this.form.method = method;
    this.form.action = action;
  }
  addInput(options) {
    const {
      type,
      name,
      value
    } = options;
    if (value !== null && name && type) {
      const input = document.createElement('input');
      input.type = type;
      input.name = name;
      input.value = value;
      this.form.appendChild(input);
    }
  }
  addFormInBody() {
    document.body.appendChild(this.form);
  }
  submitForm() {
    this.form.submit();
  }
}